import React from 'react';
import Main from '../components/Main';
import {
  SIGIL_CONTRACT_ABI,
  SIGIL_CONTRACT_ADDRESS
} from '../config'
import getWeb3 from '../adapters/Web3';
import SunCalc from 'suncalc';
const Geolocator = require('geolocator');

export default class SigilForm extends Main {

  /** returns planetary day and hour
      these are fed to the contract to provide contextual magic
      matching planetary day & hour with your intent will yield better results
      if day and hour are aligned, planetary symbols will be gold **/
  async calculateAstroData() {
    return new Promise((resolve, reject) => {
      // Geolocator.config({
      //   language: "en"
      // });
      // Geolocator.locateByIP({}, function(e, location) {
        var times;
        var returnHours = {};
      //   if (e) {
      //     console.log(e);
      //     // failed to get location, fall back to great pyramid of giza
          times = SunCalc.getTimes(new Date(), 29.97912296119251, 31.134212626312802);
      //   } else {
          // times = SunCalc.getTimes(new Date(), location.coords.latitude, location.coords.longitude);
        // }

        if (times.sunrise && times.sunset) {
          var now = new Date();
          var curDay = now.getDay();

          // divide difference between sunrise and sunset into 12 "hours"
          // do the same for night
          // find which "hour" we are in now
          var dayLenMs = Math.abs(times.sunset - times.sunrise); // milliseconds of daylight
          var dayMins = Math.floor((dayLenMs/1000)/60);
          var nightMins = 1440 - dayMins;
          var dayHourLength = dayMins / 12;
          var nightHourLength = nightMins / 12;
          var sunriseLenMs = (now - times.sunrise); // milliseconds between now & sunrise
          var minSinceSunrise = Math.floor((sunriseLenMs/1000)/60);
          var sunsetLenMs = (now - times.sunset); // milliseconds between now & sunset
          var minSinceSunset = Math.floor((sunsetLenMs/1000)/60);
          var planetaryHour;
          if (minSinceSunrise >= 0 && minSinceSunrise < dayMins) {
            // it is day time
            planetaryHour = Math.floor(minSinceSunrise / dayHourLength);
          } else if (minSinceSunrise < 0) {
            // it is before sunrise
            planetaryHour = 23 - Math.floor(-minSinceSunrise / nightHourLength);
            // planetary day is yesterday because logic
            if (curDay > 0) {
              curDay = curDay-1
            } else {
              curDay = 6
            }
          } else {
            // it is after sunset
            planetaryHour = 12 + Math.floor(minSinceSunset / nightHourLength);
          }
          // console.log(planetaryHour);

          var planetaryDay;
          switch(curDay) {
            case 0:
              planetaryDay = 4; // sun
              break;
            case 1:
              planetaryDay = 7; // moon
              break;
            case 2:
              planetaryDay = 3; // mars
              break;
            case 3:
              planetaryDay = 6; // mercury
              break;
            case 4:
              planetaryDay = 2; // jupiter
              break;
            case 5:
              planetaryDay = 5; // venus
              break;
            case 6:
              planetaryDay = 1; // saturn
              break;
            default:
              planetaryDay = 0;
          }
          // console.log(planetaryDay);

          var hourPlanet = (((planetaryHour % 7) + planetaryDay) % 7);
          if (hourPlanet === 0)
            hourPlanet = 7;

          returnHours.day = planetaryDay;
          returnHours.hour = hourPlanet;
        } else {
          // failed to get sun times, lets fall back so we don't fail to mint
          // contract should randomise if 0 received
          returnHours.day = 0;
          returnHours.hour = 0;
        }
        // console.log(times);
        resolve(returnHours);
      // })
    });
  }

  /**
    remove all chars that aren't a-z;
    remove all vowels;
    remove all duplicate chars;
    e.g. 'the big dog' becomes 'thbgd'
   */
  transformIntent(input) {
    return input.toLowerCase()
      .replace(/[^b-z]/g,'')
      .replace(/[eiou]/g,'')
      .split('')
      .filter(function(item, pos, self) {
        return self.indexOf(item) === pos;
      })
      .join('');
  }

  async validateInput(input, field) {
    return new Promise((resolve, reject) => {
      var err;
      if (!input.current) {
        err = 'no current found on input '+field
        input.current.parentNode.classList.add('input-invalid');
        input.current.parentNode.lastChild.hidden = false;
        console.log(err, input)
        resolve(err)
      }

      if (field === 'intent') {
        if (!input.current.value) {
          input.current.value = 'my will is pure and clear'
        }

        // must contain at least x chars that are a-z
        var transformedIntent = this.transformIntent(String(input.current.value));
        if (transformedIntent.length < 3 || transformedIntent.length > 21) {
          err = 'bad intent length'
          console.log(err, input.current)
          input.current.parentNode.classList.add('input-invalid');
          input.current.parentNode.lastChild.hidden = false;
          resolve(err)
        }
      }

      if (field === 'mintWith') {
        // must be numbers only
        if (!input.current.value) {
          err = 'no value found on input '+field
          console.log(err, input.current)
          input.current.parentNode.classList.add('input-invalid');
          input.current.parentNode.lastChild.hidden = false;
          resolve(err)
        }

        if (!/^\d+$/.test(input.current.value)) {
          // is not a number
          err = 'not a number '+field
          console.log(err, input.current)
          input.current.parentNode.classList.add('input-invalid');
          input.current.parentNode.lastChild.hidden = false;
          resolve(err)
        }

      }
      if (!err) {
        input.current.parentNode.classList.remove('input-invalid');
        input.current.parentNode.lastChild.hidden = true;
      }
      resolve();
    });
  }

  async buySigilClick(intent, mintWith, tab, e) {
    this.setState({ loading: true })
    e.preventDefault()

    // validation returns truthy if errors
    if (await this.validateInput(intent, 'intent')) {
      this.setState({ loading: false })
      return;
    }

    if (tab === 'mintmancy' || tab === 'mintcd') {
      if (await this.validateInput(mintWith, 'mintWith')) {
        this.setState({ loading: false })
        return;
      }
    }

    var transformedIntent = this.transformIntent(intent.current.value);

    console.log(intent.current.value)
    console.log(transformedIntent)
    console.log(mintWith)
    console.log(tab)

    var astroData = await this.calculateAstroData()
    console.log(astroData);

    var mintFunction;
    var mintProps = [];
    var mintOptions = {};

    mintFunction = this.state.sigilContract.methods['mintIncantatio']
    mintProps = [transformedIntent, astroData.day, astroData.hour]
    mintOptions = {
      from: this.state.account,
      value: this.state.sigilPrice
    }

    mintFunction(...mintProps).send(mintOptions).then((result) => {
      console.log("Success! Got result:", result);
    }).catch((err) => {
      console.log("Failed with error:", err);
    }).finally(
      this.loadBlockchainData().then((result) => {
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    );
  }

  async loadBlockchainData() {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)});
    this.setState({ account: accounts[0] });

    const sigilContract = new web3.eth.Contract(SIGIL_CONTRACT_ABI, SIGIL_CONTRACT_ADDRESS)
    this.setState({ sigilContract })
    const sigilPrice = await sigilContract.methods.claimPrice().call().catch((err) => {console.log('error getting sigilPrice:',err)})
    this.setState({ sigilPrice })

    this.setState({ loading: false });
  }

  constructor(props) {
    super(props);

    this.state = {
      sigilPrice: 0,
      sigilContract: {methods: {}},
      formErrors: {mintWith: '', intent: ''},
    }

    this.buySigilClick = this.buySigilClick.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.mintSigilIntent = React.createRef();
    this.mintSigilWith = React.createRef();
    this.loadBlockchainData = this.loadBlockchainData.bind(this);
  }

  render() {
    var preamble;
    var mintWith;

    preamble = <span>for 3 canto</span>
    mintWith = <div></div>

    return <div>
      <p><b>breathe</b></p>
      <p>consider your intention</p>
      <p><b>mint</b> {preamble}</p>
      <div class="input-group mb-3 btn-white">
        <div class="input-group-prepend">
          <button class="btn btn-sm btn-ig btn-fill">intent</button>
        </div>
        <input type="text" class="input-white form-control" placeholder="my will is pure and clear" ref={this.mintSigilIntent}/>
        <div class="warning-text" hidden>intent must contain at least 3 unique consonants</div>
      </div>
      {mintWith}
      <button disabled={!this.state.loading?"":"disabled"} onClick={(e) => this.buySigilClick(this.mintSigilIntent, this.mintSigilWith, this.props.tab, e)} class="btn btn-sm btn-white btn-fill">mint</button>
    </div>
  }
}
