import { Component } from 'react';
import getWeb3 from '../adapters/Web3';

export default class Main extends Component {
  async componentDidMount() {
    try {
      await this.loadBlockchainData();
    } catch (err) {
      console.error(err)
    }
  }

  async loadBlockchainData() {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)});
    this.setState({ account: accounts[0] });
    const network = await web3.eth.net.getId().catch((err) => {console.log('error getting network id:',err)});
    this.setState({ network })
    console.log(network)

    this.setState({ loading: false });
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      account: '',
      network: 0,
      error: false,
    }
    this.loadBlockchainData = this.loadBlockchainData.bind(this);
  }
}
