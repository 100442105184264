import Main from '../components/Main';

export default class Cmagic extends Main {
  render(){
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-100">
        <h1 class="alt-font big-title">cmagic</h1>
      </div>
      <div class="row justify-content-center align-items-center min-vh-50" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <p class="card-text text-center"><a target="_blank" rel="noreferrer" class="text-almostblack" href="https://evm.explorer.canto.io/address/0x5e2cDD7E02Faa0d5bCe626c1fB267Dd510baD971"><b>Contract:</b> 0x5e2cDD7E02Faa0d5bCe626c1fB267Dd510baD971</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <h1 class="card-title alt-font text-center">breathe</h1>
                  <p class="card-text ">The most important element of magical work, second to intention, is breath. Known by many names by many cultures, such as qi, prana, etc., the energy generated by breathing with intention is fundamental to all forms of magical practice.</p>
                  <img src="/img/logo512i.png" class="float-left border-0 rounded-0 m-3 d-none d-md-block" style={{maxWidth: '250px'}} alt="sigil example" />
                  <p class="card-text">While sigil magic can help in manifesting a specific targeted outcome, breathwork is used for a broad range of work. It is used in adding power to results based magic such as sigils, but can also be used for spiritual pathworking, general health and wellbeing improvement, increasing your power, or attaining siddhis.</p>
                  <p class="card-text">The budding magician or spiritual practitioner must learn to control their breath if they are to continue down their path. The first task of any initiate is to become fully aware of their breath throughout the day. This is achieved through meditation and specific breathing exercises derived from the tradition that the practitioner follows.</p>
                  <p class="card-text">For Chaotes, breathing exercises can be chosen from any tradition that they find conducive to success. It is recommended that the beginner tries many strategies and lands on whatever works best for them individually. Everyone is different, so proselytizing a single method only leads to disappointment.</p>
                  <p class="card-text">Start by practicing mindfulness meditation, pranayama, or Wim Hof breathing, and work from there.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-50" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <p class="card-text text-center alt-font">control your breath<br/>control your $cmagic<br/>control your destiny</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <h1 class="card-title alt-font text-center">$cmagic token</h1>
                  <p class="card-text ">The $cMAGIC token is a fair launch token with auto-buyback mechanisms, burned liquidity, and rugproof contract. It is provided to all holders of Incantatio NFTs on the Canto network at the time mint concluded.</p>
                  <p class="card-text ">Total supply of the token is 100,000,000, with 50% of the token sent to airdrop recipients, 40% sent to the liquidity pool that is burned, and the remaining 10% reserved for community promotion.</p>
                  <p class="card-text ">The token is released as a reward to loyal minters, but also forms a core part of the Incantatio ecosystem. In traditional magical systems, breath is used as a primal energy. In this new age of technomancy, we are able to find new ways to imbue working with energy. $cMAGIC is an exploration of this, and is used for on-chain experiments in not only results based magic, but soul based pathworkings as well. We will be exploring the fabric of the universe and $cMAGIC is our fuel.</p>
                  <p class="card-text ">The first of these experiments is in the spread of loving kindness. Details cannot be released lest it influence the outcome of the work, but all those who interact with the token will be involved. If you do not wish to take part in this experiment, burn your $cMAGIC immediately.</p>
                  <p class="card-text text-center alt-font">the brave are rewarded, and the mages will see the universe bend to their will</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}