import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import './App.css'
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from "./components/scrollToTop";

import Main from './components/Main';
import Incantatio from './pages/Incantatio';
import Cmagic from './pages/Cmagic';


class App extends Main {
  render() {
    return (
      <Router>
      <ScrollToTop />
      <div class="site-wrapper">
        <NavBar data={this.state} />
        {(this.state.error) && !this.state.loading
          ?<div class="container-fluid"><div class="row justify-content-center align-items-center"><div class="col-lg-5 col-warning">Error connecting - are you on the right network? Try refreshing your browser</div></div></div>
          :''
        }
        <div class="container-fluid">
          <Switch>
            <Route
              path="/cmagic"
              render={props => <Cmagic {...props} data={this.state} />}
            />
            <Route
              path="/" exact
              render={props => <Incantatio {...props} data={this.state} />}
            />
          </Switch>
        </div>
        <Footer data={this.state}/>
      </div>
      </Router>
    );
  }
}

export default App;