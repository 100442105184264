import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

export default class NavBar extends Component {
  async connectClick(e) {
    window.ethereum.request({ method: 'eth_requestAccounts' })
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  async switchNetwork(e) {
    await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x1E14'}]})
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  render() {
    return <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
      <NavLink
        className="navbar-brand"
        to='/'
      >
        <img src="/img/logo192i.png" width="50" class="d-inline-block align-top" alt="" />
      </NavLink>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0 alt-font">
          <li class="nav-item">
            <NavLink
              className="nav-link"
              activeClassName="active"
              exact
              to='/'
            >
              incantatio
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to='/cmagic'
            >
              cmagic
            </NavLink>
          </li>
        </ul>
        {this.props.data.network !== 7700 && !this.props.data.loading
            ? <ul className="navbar-nav mr-auto mt-2 mt-lg-0 alt-font"><li className="nav-item"><button class="nav-link btn btn-lg" href="#" onClick={(e) => this.switchNetwork(e)}>connect to canto</button></li></ul>
            : ''
        }
        <ul className="navbar-nav px-3 alt-font">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            {this.props.data.account
              ? <span class="nav-link btn btn-lg"><i class="fas fa-wallet fa-fw text-almostwhite text-decoration-none"></i> <span id="account">{String(this.props.data.account.slice(0,4)).toLowerCase()}</span></span>
              : this.props.data.loading ? <span class="nav-link btn btn-lg"><i class="fas fa-spinner fa-spin fa-fw text-almostwhite text-decoration-none"></i>&nbsp;</span>
              : <button class="nav-link btn btn-lg" onClick={(e) => this.connectClick(e)}><i class="fas fa-link fa-fw text-almostwhite text-decoration-none"></i> connect</button>
            }
          </li>
        </ul>
      </div>
    </nav>
  }
}