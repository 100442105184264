import React, { Component } from 'react';

export default class NavBar extends Component {
  render() {
    return <footer class="footer navbar-dark min-vh-10">
    <div class="container">
      <div class="row align-items-center text-almostwhite justify-content-center text-center">
        <div class="col-12 col-md">
          <img class="mb-2" src="/img/logo192i.png" alt="incantatio" width="48" height="48" />
          <small class="d-block mb-3 text-muted">&copy; 2023</small>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font"><a class="link-muted" target="_blank" rel="noreferrer" href="https://alto.build/collections/0xa0f293d01d0076d2ea51f99a408fb968fc67dba4">marketplace</a></h5>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font"><a class="link-muted" target="_blank" rel="noreferrer" href="https://evm.explorer.canto.io/address/0xA0F293D01D0076d2eA51f99A408Fb968fc67Dba4">contract</a></h5>
        </div>
        <div class="col-6 col-md">
          <h5 class="alt-font"><a class="link-muted" target="_blank" rel="noreferrer" href="https://twitter.com/incantat_io">twitter</a></h5>
        </div>
      </div>
    </div>
    </footer>
  }
}