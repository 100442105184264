import React from 'react';
import Main from '../components/Main';
import SigilForm from '../components/SigilForm';
import {
  SIGIL_CONTRACT_ABI,
  SIGIL_CONTRACT_ADDRESS
} from '../config'
import getWeb3 from '../adapters/Web3';

export default class Sigils extends Main {

  async loadBlockchainData() {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)});
    this.setState({ account: accounts[0] });

    const sigilContract = new web3.eth.Contract(SIGIL_CONTRACT_ABI, SIGIL_CONTRACT_ADDRESS)
    this.setState({ sigilContract })
    const sigilSupply = await sigilContract.methods.maxSupply().call().catch((err) => {console.log('error getting sigilSupply:',err)})
    this.setState({ sigilSupply })
    const sigilMinted = await sigilContract.methods.totalSupply().call().catch((err) => {console.log('error getting sigilMinted:',err)})
    this.setState({ sigilMinted })

    this.setState({ loading: false });
  }

  constructor(props) {
    super(props);

    this.state = {
      sigilSupply: 3333,
      sigilMinted: 0,
      sigilContract: {methods: {}},
    }

    this.loadBlockchainData = this.loadBlockchainData.bind(this);
  }

  render() {
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-100" id="words">
        <div class="col-lg-12 text-center"><h1 class="alt-font big-title">incantatio</h1></div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100">
        <div class="container-fluid">
          <div class="row card-text text-center p-2">
            <div class="col-lg-4 mt-5 mb-3">
              <h1 class="alt-font">on-chain sigil generator</h1>
              <span>{this.state.sigilMinted} / {this.state.sigilSupply} minted</span>
            </div>
            <div class="col-lg-8 col-xl-7 col-grid">
              {/* <div class="card">
                <div class="card-body d-flex flex-column h-100 alt-font tab-content">
                  <div class="tab-pane show active text-almostwhite" id="mint">
                    <SigilForm tab="mint"></SigilForm>
                  </div>
                </div>
              </div> */}
              <div class="row align-items-center m-5">
                <div class="card p-5 alt-font w-100 text-almostwhite">
                  <p style={{fontSize: '3vh'}}>mint has concluded<hr />thanks to all who participated</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="a">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100 text-center">
                  <img src="/img/sigil-1.png" class="border-0 rounded-0" style={{maxWidth: '300px'}} alt="sigil example" />
                  <h3 class="card-title alt-font mt-5">immutable record of manifestation</h3>
                  <p class="card-text mt-auto">Mint an artistic <b>fully on-chain sigil</b> that is activated by the immense energy of the blockchain itself, the gas fee acting as an energetic and material sacrifice.</p>
                  <p class="card-text">The sigil is stored in your wallet to link the effects to you, and can be transferred to a target or resold.</p>
                  <blockquote class="alt-font">for pure will, unassuaged of purpose, delivered from the lust of result, is every way perfect</blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <h1 class="card-title alt-font text-center">about sigil magic</h1>
                  <p class="card-text ">In the practice of results-based magic, sigils are hand crafted images designed to embody the practitioner's intent. They are an essential tool in the Western magician's belt, and have been popularized in recent decades thanks to the work of modern day chaos magic practitioners.</p>
                  <img src="/img/sigil-3.png" class="float-left border-0 rounded-0 m-3 d-none d-md-block" style={{maxWidth: '250px'}} alt="sigil example" />
                  <p class="card-text">Meditating deeply on the desired outcome of their work, the magician will determine a succinct phrase that encapsulates the outcome in a way that is <b>free from desire and clear in execution</b>. The phrase is then turned into a symbol through any of a plethora of methods, and activated through a method that the practitioner feels is most suitable.</p>
                  <p class="card-text">Incantatio takes your intent phrase and does the rest, using a method of sigil crafting known as magic circles. The phrase is reduced to unique latin consonants and plotted to randomly generated coordinates around the circumference of a circle, resulting in a path like you see in the example images.</p>
                  <p class="card-text">We then determine your local planetary day and hour and add symbols for these to the generated image. Planetary magic can amplify the effectiveness of magical workings if done right - for example, casting money making sigils during the hours and days of Jupiter.</p>
                  <p class="card-text">Finally we sprinkle some on-chain randomness to determine the colors of the sigil, picking from a set of radiant hand crafted gradients and line colors. If luck is on your side, a special texture may be applied to the sigil as well.</p>
                  <p class="card-text">Leave it in your wallet, send it to a friend or loved one, or use it as a profile pic to get more eyes and energy on your outcome.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center min-vh-100" id="b">
        <div class="col-lg-9">
          <div class="text-almostblack border-0">
            <div class="row no-gutters">
              <div class="col-lg-12">
                <div class="card-body h-100">
                  <h1 class="card-title alt-font text-center">faq</h1>
                  <img src="/img/sigil-2.png" class="float-right border-0 rounded-0 m-3 d-none d-md-block" style={{maxWidth: '250px'}} alt="sigil example" />
                  <p class="card-text "><b>How do I pick an intention?</b></p>
                  <blockquote>Your intent should be crafted in a way that is clear, free of desire, and framed in the present - it should not contain words like 'want' or 'need', but instead focus on a statement of fact. For example, 'New opportunities for wealth continually arise for me' or 'I am in perfect health'.</blockquote>
                  <blockquote>The results must be attainable too - magic manifests through synchronicity, so you need to give the universe space to give you what you're demanding. It makes no sense to try and manifest a job if you don't apply for any.</blockquote>
                  <p class="card-text "><b>Do I need to activate the sigil after minting?</b></p>
                  <blockquote>No, but it doesn't hurt to pour as much energy as you can into it. Additional activation can be performed by techniques such as deep focus meditation, breathing exercises, or simply by displaying the sigil proudly as your profile picture and sucking up the collective energy of its viewers.</blockquote>
                  <p class="card-text "><b>Is this safe?</b></p>
                  <blockquote>Yes - you can't accidentally summon an entity or screw up your life, and you probably can't deliberately cause harm to others with this style of magic, but it's still worth considering the karmic implications of any magic you perform.</blockquote>
                  <p class="card-text "><b>What is the rarity?</b></p>
                  <blockquote>Each sigil is 100% unique - in addition to the shuffle mechanism of the sigil lines and the plantery day/hour added to the image, the background gradient and line color are chosen randomly. There is also a chance that the sigil will have an extremely rare texture applied to the background. The rarest of all sigils is pure gold.</blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

